import React, { Component } from "react";
import { Freshchat } from "reactjs-freshchat";
import "reactjs-freshchat/dist/index.css";

export default class FreshChat extends Component {
    
  render() {
    return <Freshchat token={"eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpS216TTVkenRIWmprdmdSY3VrVHgxTzJ2SFlTM0U5YmVJME9XbXRNR1ZzIn0.eyJqdGkiOiJlOGJmYzdjNy0zNTZlLTRkMDMtOTQ4OC00Y2ZlYTRiYzUzMGYiLCJleHAiOjE5Mjk3ODg5NzgsIm5iZiI6MCwiaWF0IjoxNjE0NDI4OTc4LCJpc3MiOiJodHRwOi8vaW50ZXJuYWwtZmMtdXNlMS0wMC1rZXljbG9hay1vYXV0aC0xMzA3MzU3NDU5LnVzLWVhc3QtMS5lbGIuYW1hem9uYXdzLmNvbS9hdXRoL3JlYWxtcy9wcm9kdWN0aW9uIiwiYXVkIjoiNDkxMDVjMTctMmJhOC00ZjU2LWE4NmQtYWNlZDE2NmVmYmMwIiwic3ViIjoiZDM5OTlhMjQtOWEzNi00OTEwLWI4YTgtZDMyMzE1MWIwZTI1IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiNDkxMDVjMTctMmJhOC00ZjU2LWE4NmQtYWNlZDE2NmVmYmMwIiwiYXV0aF90aW1lIjowLCJzZXNzaW9uX3N0YXRlIjoiMTA1YzRhMDktNDc3My00ZWUwLThhMWQtOTBkNjJkM2Y5YWJmIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6W10sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJhZ2VudDp1cGRhdGUgYWdlbnQ6Y3JlYXRlIG1lc3NhZ2U6Y3JlYXRlIG1lc3NhZ2U6Z2V0IHVzZXI6ZGVsZXRlIGZpbHRlcmluYm94OmNvdW50OnJlYWQgdXNlcjp1cGRhdGUgcm9sZTpyZWFkIGltYWdlOnVwbG9hZCBiaWxsaW5nOnVwZGF0ZSByZXBvcnRzOmV4dHJhY3QgY29udmVyc2F0aW9uOnJlYWQgZGFzaGJvYXJkOnJlYWQgcmVwb3J0czpleHRyYWN0OnJlYWQgcmVwb3J0czpyZWFkIGFnZW50OnJlYWQgZmlsdGVyaW5ib3g6cmVhZCBjb252ZXJzYXRpb246dXBkYXRlIGNvbnZlcnNhdGlvbjpjcmVhdGUgYWdlbnQ6ZGVsZXRlIG91dGJvdW5kbWVzc2FnZTpnZXQgb3V0Ym91bmRtZXNzYWdlOnNlbmQgdXNlcjpjcmVhdGUgcmVwb3J0czpmZXRjaCB1c2VyOnJlYWQiLCJjbGllbnRJZCI6IjQ5MTA1YzE3LTJiYTgtNGY1Ni1hODZkLWFjZWQxNjZlZmJjMCIsImNsaWVudEhvc3QiOiIxOTIuMTY4LjEyOC4yMTYiLCJjbGllbnRBZGRyZXNzIjoiMTkyLjE2OC4xMjguMjE2In0.I-eJMHyygudC0fsLgFg3IySyL4-XyvUwR3et5pmxGWMQIR8eNRIIDi5-kalmm1ixzX6gC09nkfAilegwWb9AmE-eu2Kfgu2gxJObVigx4aVI-sIt_on9XuvowPckrWX-vA71qGkTnIHsTcTJbWMAd8cfx8nS1dETjVuNX5iUoI40_jBwXqHV57Hk-7tBXysJtGsS9p8AH3iaLaCum5h9iBXnWb82mkM-VXmLi27upUjne-DR3-hM28nV8Fw0QJkBOkB0nSeCO3eEnHj-8cnItCMwzt549eXhwb8V79ANn_4qpZBkSnFUJAeYzcdtoq1C_-mb9FY1QOWTK_ZOtWjEcA"} />;
  }
}
