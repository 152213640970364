import React, { Suspense } from "react";
import { connect } from "react-redux";
import "./App.less";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import ProtectRoute from "./Routing/ProtectRouting";
import { message } from "antd";
import CookieModal from "./Components/CookieModal";

import Home from "./Website/Home";
import PageLoading from "./Components/PageLoading";
import Pricing from "./Website/Pricing/Pricing";
import CollectService from "./Website/CollectService";
import TermsCondition from "./Website/TermsCondition";

import Contactus from "./Website/Help-Support/index";

import RegisteredPost from "./Website/Registered-Post";

import CustomerAdvocateForm from "./Website/CustomerAdvocateForm/CustomerAdvocateForm";

const Login = React.lazy(() => import("./Website/Login"));
const Register = React.lazy(() => import("./Website/Register"));
const ContactUs = React.lazy(() => import("./Website/ContactUs"));
const GettingItSorted = React.lazy(() =>
  import("./Website/Help-Support/Getting-it-sorted")
);
const Privacy = React.lazy(() => import("./Website/Privacy"));
const TradingTerms = React.lazy(() => import("./Website/TradingTerms"));
const Partners = React.lazy(() => import("./Website/Partners"));
const SDDelivery = React.lazy(() => import("./Website/SDDelivery"));

const Careers = React.lazy(() => import("./Website/Careers"));
const CareerDetails = React.lazy(() => import("./Website/Careers/Details"));

const mobileAppDetails = React.lazy(() => import("./Website/mobileAppDetails"));
const Booking = React.lazy(() => import("./Website/Booking"));
const BookingTracking = React.lazy(() => import("./Website/BookingTracking"));
const Routing = React.lazy(() => import("./Routing"));
const CarrierPolicy = React.lazy(() => import("./Website/CarrierPolicy"));
const CourierService = React.lazy(() => import("./Website/CourierService"));
const CreditPolicy = React.lazy(() => import("./Website/CreditPolicy"));
const NotFound = React.lazy(() => import("./Website/ErrorPages/NotFound"));
const ShopifyStore = React.lazy(() => import("./Website/ShopifyStore"));
const CollectDeliver = React.lazy(() => import("./Website/CollectDeliver"));
const HolidayNotice = React.lazy(() => import("./Website/HolidayNotice"));
const Navigation = React.lazy(() => import("./Website/Navigation/Navigation"));
const PayBooking = React.lazy(() => import("./Website/PayBooking"));
const Pay = React.lazy(() => import("./Website/PayBooking/Pay"));
const PaySuccess = React.lazy(() =>
  import("./Website/PayBooking/Pay/paySuccess")
);
class App extends React.Component {
  componentDidMount() {
    message.config({ top: 100 });
    document.addEventListener("keydown", this.keydownHandler);
  }

  render() {
    return (
      <Suspense fallback={<PageLoading />}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/holidaynotice" component={HolidayNotice} />
          <Route path="/collectionservice" component={CollectDeliver} />
          <Route path="/shopifystore" component={ShopifyStore} />
          <Route path="/careers" component={Careers} />
          <Route path="/career-details/:id" component={CareerDetails} />
          <Route path="/features" component={mobileAppDetails} />
          <Route path="/Booking" component={Booking} />
          <Route path="/track/:bookid" component={BookingTracking} />

          <Route path="/PayBooking" component={PayBooking} />
          <Route path="/pay/:amt/:id/:type" component={Pay} />
          <Route path="/success" component={PaySuccess} />

          <Route path="/register/:role" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms-and-conditions" component={TermsCondition} />
          <Route path="/carrier-policy" component={CarrierPolicy} />
          <Route path="/creditpolicy" component={CreditPolicy} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/tradingterms" component={TradingTerms} />
          <Route
            path="/services/same-day-parcel-delivery-service"
            component={SDDelivery}
          />
          <Route
            path="/services/cheap-parcel-and-courier-delivery"
            component={CourierService}
          />
          <Route
            path="/services/parcel-king-routing-system"
            component={Navigation}
          />
          <Route
            path="/services/parcel-collection-and-delivery-service"
            component={CollectDeliver}
          />
          <Route
            path="/services/parcel-collection-service"
            component={CollectService}
          />
          <Route path="/partners" component={Partners} />
          <Route
            path="/Help-Support/Getting-it-sorted"
            component={GettingItSorted}
          />
          <Route path="/Help-Support/Contact-Us" component={Contactus} />
          <Route
            path="/Help-Support/Customer-Advocate-Form"
            component={CustomerAdvocateForm}
          />
          <Route path="/Registered-Post" component={RegisteredPost} />

          <ProtectRoute
            path="/Auth"
            authed={this.props.User.auth}
            component={Routing}
          />
          <Route path="*" component={NotFound} />
        </Switch>
        <CookieModal />
      </Suspense>
    );
  }
}
function mapStateToProps(state) {
  return {
    User: state.User,
  };
}
export default connect(mapStateToProps)(App);
