import React, { useState, useEffect } from "react";
import "./styles.css";
import { Container, Navbar, Nav, Row, Col, NavDropdown } from "react-bootstrap";
import Logo from "../../Assets/Images/logo.png";
import ProfileFavicon from "../../Assets/Images/ProfileFavicon.png";
import { CgDetailsMore } from "react-icons/cg";
import { IoIosArrowDown } from "react-icons/io";
import { withRouter } from "react-router-dom";
import { Popover, Button } from "antd";
import { CardHeader, Grid, IconButton } from "@material-ui/core";

import SameDayDelivery from "../../Assets/Images/same day delivery.svg";
import CourierService from "../../Assets/Images/cheap courier service.svg";
import RoutingApp from "../../Assets/Images/Routing.svg";

import CollectAndDeliver from "../../Assets/Images/collect & deliver.svg";
import collectionService from "../../Assets/Images/collection service (1).svg";
import CourierRerurns from "../../Assets/Images/parcel & courier return.svg";

import TrackAParcel from "../../Assets/Images/postage & parcel tracking.svg";
import internationalDelivery from "../../Assets/Images/internaitonal parcel.svg";
import DeliveryToUk from "../../Assets/Images/courier to uk.svg";

import { Close } from "@material-ui/icons";

function OtherHeader(props) {
  const [isTop, setTop] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const tsTop = window.scrollY < 100;
      if (tsTop !== isTop) {
        setTop(tsTop);
      }
    });
  });

  const navigate = (screen) => {
    props.history.push(screen);
  };
  const navigateHtml = (screen) => {
    window.location.href = screen;
  };

  const showMenu = () => { };
  return (
    <Navbar
      expand="lg"
      className={isTop ? "MainHeader scrolled" : "MainHeader scrolled"}
      collapseOnSelect
      sticky="top"
    >
      <Container>
        <Navbar.Brand href="/">
          <div className="MainHeader-box">
            <img
              src={Logo}
              className={isTop ? "MainHeader-img" : "MainHeader-img-scrolled"}
              alt="parcel-king"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="pt-3 MainHeader-center navbar-nav"
          id="responsive-navbar-nav"
        >
          <Nav className="m-auto"></Nav>
          <Nav className={`MainHeader-center mobile-menu`}>
            <Nav.Link onClick={() => navigate("/")}>
              <div className="MainHeader-txt2">Home</div>
            </Nav.Link>
            <Nav.Link>
              <Popover
                content={
                  <Grid className="w-100" container spacing={3}>
                    <Grid item xs={4}>
                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "/services/same-day-parcel-delivery-service.html"
                              )
                            }
                          >
                            <div className="header">Same-Day Delivery</div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={SameDayDelivery}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Collect and Deliver on the Same Day
                          </div>
                        }
                      />
                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "/services/cheap-parcel-and-courier-delivery.html"
                              )
                            }
                          >
                            <div className="header">Cheap Courier Service</div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={CourierService}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Lowest rates in the Country
                          </div>
                        }
                      />

                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml("/services/postage-tracking.html")
                            }
                          >
                            <div className="header">
                              Postage & Parcel Tracking
                            </div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={TrackAParcel}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            How to track your parcel in Ireland and
                            Internationally
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "/services/parcel-collection-and-delivery-service.html"
                              )
                            }
                          >
                            <div className="header">Collect & Deliver</div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={CollectAndDeliver}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Collect your parcels in Ireland & Internationally
                            from € 5.95
                          </div>
                        }
                      />

                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "/services/parcel-collection-service.html"
                              )
                            }
                          >
                            <div className="header">
                              Parcel Collection Services
                            </div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={collectionService}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            We collect and We Deliver
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "/services/parcel-king-routing-system.html"
                              )
                            }
                          >
                            <div className="header">Routing & Navigation</div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={RoutingApp}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Our Routing App, Makes Navigation Easier
                          </div>
                        }
                      />

                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "/services/parcel-and-courier-returns.html"
                              )
                            }
                          >
                            <div className="header">
                              Parcel & Courier Return
                            </div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={CourierRerurns}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Return your parcel and couriers hassle-free
                          </div>
                        }
                      />

                    </Grid>
                  </Grid>
                }
                title="Services"
              >
                <div className="MainHeader-txt2">
                  Services <IoIosArrowDown />
                </div>
              </Popover>
            </Nav.Link>
            {/* international */}
            <Nav.Link>
              <Popover
                content={
                  <Grid className="w-100" container spacing={3}>
                    <Grid item xs={4}>
                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "international-parcel-service.html"
                              )
                            }
                          >
                            <div className="header">
                              International Parcel & Courier Delivery Service
                            </div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={internationalDelivery}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Ship parcels and couriers internationally at best
                            rates
                          </div>
                        }
                      />

                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "international-parcel-delivery/courier-to-south-africa.html"
                              )
                            }
                          >
                            <div className="header">
                              Courier Services to South Africa
                            </div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={internationalDelivery}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Send parcels and deliver couriers to South Africa at
                            best prices
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "international-parcel-delivery/courier-to-uk.html"
                              )
                            }
                          >
                            <div className="header">Courier Services to UK</div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={DeliveryToUk}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Send parcels and deliver couriers to UK at best
                            prices
                          </div>
                        }
                      />

                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "international-parcel-delivery/courier-to-germany.html"
                              )
                            }
                          >
                            <div className="header">
                              Courier Services to Germany
                            </div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={DeliveryToUk}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Send parcels and deliver couriers to Germany at best
                            prices
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "international-parcel-delivery/courier-to-usa.html"
                              )
                            }
                          >
                            <div className="header">
                              Courier Services to USA
                            </div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={DeliveryToUk}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Send parcels and deliver couriers to USA at best
                            prices
                          </div>
                        }
                      />
                      <CardHeader
                        title={
                          <div
                            className="MainHeader-Popover-item"
                            onClick={() =>
                              navigateHtml(
                                "international-parcel-delivery/courier-to-australia.html"
                              )
                            }
                          >
                            <div className="header">
                              Courier Services to Australia
                            </div>
                          </div>
                        }
                        avatar={
                          <img
                            className="headItem-image"
                            // style={{ width: 50, height: 50 }}
                            src={DeliveryToUk}
                          />
                        }
                        subheader={
                          <div className="muted small">
                            Send parcels and deliver couriers to Australia at
                            best prices
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                }
                title="International Delivery"
              >
                <div className="MainHeader-txt2">
                  International <IoIosArrowDown />
                </div>
              </Popover>
            </Nav.Link>

            <Nav.Link onClick={() => navigate("/pricing")}>
              <div className="MainHeader-txt2">Pricing</div>
            </Nav.Link>
            <Nav.Link onClick={() => navigate("/contact-us")}>
              <div className="MainHeader-txt2">Contact Us</div>
            </Nav.Link>

            <Nav.Link onClick={() => navigate("/register/user")}>
              <div className="MainHeader-txt2">Register</div>
            </Nav.Link>
            <Nav.Link onClick={() => navigate("/login")}>
              <div className="MainHeader-txt2">Login</div>
            </Nav.Link>


            {/* <Nav.Link className="mobile-menu-link">
              <Button block type="primary" onClick={() => navigate("/login")}>
                Login
              </Button>
            </Nav.Link>
            <Nav.Link className="mobile-menu-link">
              <Button
                block
                type="primary"
                onClick={() => navigate("/register/user")}
              >
                Register
              </Button>
            </Nav.Link>
            <Nav.Link>
              <Popover
                placement="bottomRight"
                overlayStyle={{ width: "40vh" }}
                content={
                  <div>
                    <Row style={{ padding: 5 }}>
                      <Col sm="6" xs="6" style={{ padding: 5 }}>
                        <Button block onClick={() => navigate("/Login")}>
                          Login
                        </Button>
                      </Col>
                      <Col sm="6" xs="6" style={{ padding: 5 }}>
                        <Button
                          block
                          type="primary"
                          onClick={() => navigate("/register/user")}
                        >
                          Register
                        </Button>
                      </Col>
                    </Row>
                  </div>
                }
                title="My Account"
              >
                <img
                  src={ProfileFavicon}
                  className="MainHeader-loginIcon"
                  alt="parcel-king"
                />
              </Popover>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default withRouter(OtherHeader);
