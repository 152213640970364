import * as types from "../Action/Types";
const response = {
  type: null,
  return_URL: "",
  data: [],
};

const PaymentReducer = (state = response, action) => {
  switch (action.type) {
    case types.SAVE_PAYMENT: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case types.PAYMENT_TYPE: {
      return {
        ...state,
        type: action.payload,
      };
    }
    case types.PAYMENT_URL: {
      return {
        ...state,
        return_URL: action.payload,
      };
    }
    case types.REMOVE_PAYMENT: {
      return {
        ...state,
        type: null,
        data: [],
      };
    }
    default: {
      return state;
    }
  }
};
export default PaymentReducer;
