import * as types from "../Action/Types";
const response = {
  loading: false,
  errormsg: null,
  auth: false,
  user: {},
  clockInOut: {},
  shopify: {},
  credit: 0,
};

const LoginReducer = (state = response, action) => {
  switch (action.type) {
    case types.LOGIN_RQUEST: {
      return {
        ...state,
        loading: true,
        errormsg: null,
      };
    }
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        auth: true,
        errormsg: null,
        user: action.payload,
      };
    }

    case types.LOGIN_FAILD: {
      return {
        ...state,
        loading: false,
        errormsg: action.payload,
      };
    }

    case types.CLOCK_INOUT: {
      return {
        ...state,
        loading: false,
        clockInOut: action.payload,
      };
    }

    case types.SAVE_CREDIT: {
      return {
        ...state,
        credit: action.payload,
      };
    }

    case types.SHOPIFY_URL: {
      return {
        ...state,
        shopify: action.payload,
      };
    }

    case types.LOGOUT: {
      return response;
    }

    default: {
      return state;
    }
  }
};
export default LoginReducer;
