import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import BoockCol from "../../Assets/Images/BoockCol.gif";
import WeCollect from "../../Assets/Images/WeCollect.gif";
import WeDeliver from "../../Assets/Images/WeDeliver.gif";
import Arrow1 from "../../Assets/Images/arrow1.png";
import Arrow2 from "../../Assets/Images/arrow2.png";
function How() {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Container>
        <h1 className="home-txt4">How Does Parcel-King Work?</h1>
        <br /> <br />
        <Row>
          <Col sm="3" xs="12">
            <div className="home-count-box">
              <img src={BoockCol} className="home-How-img" alt="parcel-king" />
              <h4 className="home-txt10">Book Collection</h4>
              <h6 className="home-txt11">
                Simply enter collection & delivery details, and confirm your
                booking with us
              </h6>
              <br />
              <br />
            </div>
          </Col>
          <Col sm="1" xs="12">
            <div className="home-center-box">
              <img src={Arrow1} />
            </div>
          </Col>
          <Col sm="4" xs="12">
            <div className="home-count-box">
              <img src={WeCollect} className="home-How-img" alt="parcel-king" />
              <h4 className="home-txt10">We Collect</h4>
              <h6 className="home-txt11">
                We have scheduled and on-time courier pick-ups, which can be
                booked at your earliest convenience
              </h6>{" "}
              <br />
              <br />
            </div>
          </Col>
          <Col sm="1" xs="12">
            <div className="home-center-box">
              <img src={Arrow2} />
            </div>
          </Col>
          <Col sm="3" xs="12">
            <div className="home-count-box">
              <img src={WeDeliver} className="home-How-img" alt="parcel-king" />
              <h4 className="home-txt10">We Deliver</h4>
              <h6 className="home-txt11">
                We ensure same-day delivery in County Dublin and Next-Day
                delivery elsewhere in Ireland.
              </h6>{" "}
              <br />
              <br />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default withRouter(How);
