import * as types from "../Action/Types";

const response = {
  data: [],
  loading: false,
};

const RefreshReducer = (state = response, action) => {
  switch (action.type) {
    case types.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case types.REFRESH_CREDITS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default RefreshReducer;
