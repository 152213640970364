import React from "react";
import OtherHeader from "../../Components/OtherHeader";
import Footer from "../../Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.css";

function RegisteredPost() {
  return (
    <div>
      <OtherHeader />
      <Container>
        <br />
        <br />
        <br />
        <h2 className="Registered-Post-txt1">
          Registered Post for security and peace of mind
        </h2>
        <div className="Registered-Post-txt2">
          Are you posting valuable or important items? Do you need to know your
          letter or parcel has arrived safely? An Post’s Registered Post service
          provides a secure and reliable way to send items nationally or
          internationally, with proof of postage and a signature on delivery to
          give you peace of mind.
        </div>
        <h2 className="Registered-Post-txt1">What it offers</h2>
        <ul className="CustomerAdvocateForm-list">
          <li>Proof the item was posted</li>
          <li>
            Proof of receipt with a signature, available online for deliveries
            in Ireland only
          </li>
          <li>Secure delivery to over 200 destinations worldwide</li>
          <li>
            Online tracking of Registered Post depending on the destination
          </li>
          <li>Insurance cover for valuable items</li>
          <li>Compensation if your item is not received</li>
        </ul>
        <h2 className="Registered-Post-txt1">How it works?</h2>
        <ol className="GettingItSorted-list">
          <li>Buy a Registered Post label online or at any post office</li>
          <li>Declare the value of your item</li>
          <li>Attach the Registered Post label</li>
          <li>
            Complete the appropriate customs documentation for international
            items
          </li>
          <li>Hand the item over the counter</li>
          <li>
            Get a transaction receipt as proof of postage with a Track & Trace
            ID number
          </li>
          <li>
            Ask for a certificate of posting if you require evidence of the
            addressee
          </li>
          <li>
            Keep your transaction receipt as claims cannot be processed without
            it
          </li>
        </ol>
        <h2 className="Registered-Post-txt1">What it costs?</h2>
        <div className="Registered-Post-txt2">
          Registered Post charges depend on the size and weight of the item you
          are posting and its destination. Check our postal rates for details.
        </div>
        <br />
        <div className="Registered-Post-txt2">
          Find out if International Registered Post is available to your chosen
          country by using our Calculate the Postage tool, which also gives you
          expected delivery times.
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default RegisteredPost;
