import * as types from "../Action/Types";
const response = {
  data: [],
  pickup: [],
  delivery: [],
};

const AddressReducer = (state = response, action) => {
  switch (action.type) {
    case types.SAVE_ADDRESS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case types.SAVE_DELIVERY_ADDRESS: {
      return {
        ...state,
        delivery: action.payload,
      };
    }
    case types.SAVE_PICKUP_ADDRESS: {
      return {
        ...state,
        pickup: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default AddressReducer;
