import React, { useState, useEffect } from "react";
import "./styles.css";
import { Navbar, Nav, NavbarBrand, Container, NavbarText } from "reactstrap";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import RoleModal from "./RoleModal";
import MobileMenu from "./MobileMenu";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: -2,
    [theme.breakpoints.down("sm")]: {
      color: "balck",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  headerItem: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function HomeHeader(props) {
  const [isTop, setTop] = useState(true);
  const [reg, setreg] = useState(false);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const tsTop = window.scrollY < 100;
      if (tsTop !== isTop) {
        setTop(tsTop);
      }
    });
  });

  const mobileRegister = () => {
    setMobileOpen(false);
    setreg(true);
  };

  return (
    <header className={isTop ? "custom-nav" : "custom-nav-scrolled"}>
      <Navbar style={{ padding: 10 }}>
        <Container>
          <NavbarBrand href="/" style={{ margin: 0 }}>
            <img
              style={
                isTop
                  ? { width: "100px", height: "85px" }
                  : { width: "70px", height: "55px" }
              }
              src={require("./../Images/logo2.png")}
              alt="logo"
            />
          </NavbarBrand>
          <Nav className="ml-auto" navbar></Nav>
          <Link to={"/"} className={classes.headerItem}>
            <NavbarText
              className={`nav-bar-text ${
                isTop ? "headerItemHeading" : "headerItemHeading-scrolled"
              }`}
            >
              Home
            </NavbarText>
          </Link>
          <span className={classes.headerItem}>
            <Dropdown
              overlay={
                <Menu className="px-4">
                  <Menu.Item className="submenu">
                    <Link
                      rel="noopener noreferrer"
                      to="/services/same-day-collection-and-delivery"
                    >
                      Same Day Delivery
                    </Link>
                  </Menu.Item>
                  <Menu.Item className="submenu">
                    <Link
                      rel="noopener noreferrer"
                      to="/services/parcel-king-routing-system"
                    >
                      Navigation
                    </Link>
                  </Menu.Item>
                </Menu>
              }
            >
              <NavbarText
                className={`nav-bar-text ${
                  isTop ? "headerItemHeading" : "headerItemHeading-scrolled"
                }`}
                onClick={(e) => e.preventDefault()}
              >
                Services
              </NavbarText>
            </Dropdown>
          </span>
          <Link to={"/pricing"} className={classes.headerItem}>
            <NavbarText
              className={`nav-bar-text ${
                isTop ? "headerItemHeading" : "headerItemHeading-scrolled"
              }`}
            >
              Pricing
            </NavbarText>
          </Link>
          <Link to={"/Login"} className={classes.headerItem}>
            <NavbarText
              className={`nav-bar-text ${
                isTop ? "headerItemHeading" : "headerItemHeading-scrolled"
              }`}
            >
              <span className="login-btn">Login</span>
            </NavbarText>
          </Link>
          <Link onClick={() => setreg(true)} className={classes.headerItem}>
            <NavbarText
              className={`nav-bar-text ${
                isTop ? "headerItemHeading" : "headerItemHeading-scrolled"
              }`}
            >
              <span className="registerbtn">Register</span>
            </NavbarText>
          </Link>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon style={{ fontSize: 30, color: "#fff" }} />
          </IconButton>
        </Container>
      </Navbar>
      <RoleModal visible={reg} close={() => setreg(false)} />
      <MobileMenu
        visible={mobileOpen}
        close={() => setMobileOpen(false)}
        mobileRegister={() => mobileRegister()}
      />
    </header>
  );
}
export default HomeHeader;
