/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-west-1",
  aws_appsync_graphqlEndpoint:
    "https://mcbx5ctvxzdhdiz24chbok6cxq.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-kyymo3odpzg3jawqfwapqolose",
  aws_cognito_identity_pool_id:
    "eu-west-1:429edb46-a912-4dbc-9ad2-c2e7a457d7af",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_fQOSWHuAw",
  aws_user_pools_web_client_id: "5ktvq45ojbva6ef6h6ld1pseak",
  oauth: {},
};

export default awsmobile;
