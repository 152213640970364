import React, { useState, useEffect } from "react";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import Fade from "react-reveal/Fade";
import MainHeader from "../../Components/MainHeader";
import Footer from "../../Components/Footer";
import API from "../../config/API";

import GetPrice from "./GetPrice";
import TrackBooking from "./TrackBooking";
import Counts from "./Counts";
import Why from "./Why";
import How from "./How";
import ServicesTable from "./ServicesTable";
import Partners from "./Partners";
import Faq from "./Faq";
import AboutSection from "./AboutSection";
import ComplimentaryServices from "./ComplimentaryServices";
import InfoModal from "../../Components/InfoModal";
import AnnoucmentModal from "../../Components/AnnoucmentModal";
function Home(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    loaData();
    
    
  }, []);

  const loaData = () => {
    fetch(process.env.REACT_APP_API_KEY + API.HOME_COUNTS, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          setData(responseJson);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigate = (screen) => {
    props.history.push(screen);
  };

  return (
    <>
   
      <MainHeader />
      <div className="home-screen">
        <div className="home-carousel">
          <div className="home-carousel-img1">
            <Container>
              <Row>
                <Col sm="6" xs="12">
                  <Fade bottom>
                    <h1 className="home-txt1">We Collect, Deliver</h1>
                    <h2 className="home-txt3">or Post on your behalf.</h2>
                    <h5 className="home-txt2">
                      Same Day & Next Day Parcel Delivery
                      <br />
                      starting from € 5.95
                    </h5>
                    <h6 className="home-txt2">
                      Register today and Get € 100 Credit
                    </h6>
                    <h6 className="home-txt2">
                      We Started Saturday Deliveries And Bank Holiday Services.
                    </h6>
                    <br />
                    <div className="home-centerbtn">
                      <Button
                        type="primary"
                        onClick={() => navigate("/PayBooking")}
                      >
                        Book Now
                      </Button>
                    </div>
                    <br /> <br />
                  </Fade>
                </Col>
                <Col sm="1" xs="12"></Col>
                <Col sm="5" xs="12">
                  <Fade bottom>
                    <br /> <br />
                    <GetPrice />
                    <br />
                    <TrackBooking />
                  </Fade>
                </Col>
              </Row>
             
            </Container>
            
          </div>      
        </div>
        {data.packageCount ? (
          <>
            <Fade bottom>
              <br />
              <Counts data={data} /> <br /> <br /> <br />
            </Fade>
          </>
        ) : null}
        <Fade bottom>
          <AboutSection />
        </Fade>
        <br /> <br />
        <Fade bottom>
          <Why />
        </Fade>
        <br /> <br />
        <Fade bottom>
          <ServicesTable />
        </Fade>
        <br /> <br /> <br />
        <Fade bottom>
          <How />
        </Fade>
        <br />
        <Fade bottom>
          <ComplimentaryServices />
        </Fade>
        <br /> <br />
        <Fade bottom>
          <Partners />
        </Fade>
        <Fade right>
          <Faq />
        </Fade>
        <br /> <br />
      </div>
      
      <Footer />
    </>
  );
}
export default Home;
