import React from "react";
import {
  Box,
  Container,
  makeStyles,
  LinearProgress,
  Paper,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    height: "100vh",
  },
  colorPrimary: {
    backgroundColor: "#fafafa",
  },
  barColorPrimary: {
    backgroundColor: "#ffc400",
  },
}));
const PageLoading = () => {
  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      className={classes.root}
      title="Fetching Details | Parcel-King"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        className="m-5 p-5"
      >
        <Container>
          <center style={{ textAlign: "center", color: "grey" }}>
            Loading, Please wait....
          </center>
          <br /> <br />
          <LinearProgress
            classes={{
              colorPrimary: classes.colorPrimary,
              barColorPrimary: classes.barColorPrimary,
            }}
          />
        </Container>
      </Box>
    </Paper>
  );
};

export default PageLoading;
