import React from "react";
import "./styles.css";
import { Modal } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
class RoleModal extends React.Component {
  render() {
    return (
      <Modal
        visible={this.props.visible}
        onCancel={() => this.props.close()}
        footer={null}
        centered
        width={800}
      >
        <div className="RoleModal">
          <div className="RoleModaltxt1">Create New Account</div>
          <div className="RoleModaltxt2">
            To continue, please pick one of the following systems:
          </div>
          <br />
          <div>
            <Row>
              <Col sm="6" xs="12">
                <Link to="/register/user" onClick={() => this.props.close()}>
                  <div className="RoleModalBox">
                    <div>
                      <div>User</div>
                    </div>
                    <div>
                      <span class="material-icons">chevron_right</span>
                    </div>
                  </div>
                </Link>
                <br />
              </Col>
              <Col sm="6" xs="12">
                <Link
                  to="/Register/contractor"
                  onClick={() => this.props.close()}
                >
                  <div className="RoleModalBox">
                    <div>
                      <div>Contractor</div>
                    </div>
                    <div>
                      <span class="material-icons">chevron_right</span>
                    </div>
                  </div>
                </Link>

                <br />
              </Col>
              <Col sm="6" xs="12">
                <Link
                  to="/Register/retailer"
                  onClick={() => this.props.close()}
                >
                  <div className="RoleModalBox">
                    <div>
                      <div>Retailer</div>
                    </div>
                    <div>
                      <span class="material-icons">chevron_right</span>
                    </div>
                  </div>
                </Link>
                <br />
              </Col>
              <Col sm="6" xs="12">
                <Link to="/Register/driver" onClick={() => this.props.close()}>
                  <div className="RoleModalBox">
                    <div>
                      <div>Driver</div>
                    </div>
                    <div>
                      <span class="material-icons">chevron_right</span>
                    </div>
                  </div>
                </Link>
                <br />
              </Col>
              <Col sm="6" xs="12">
                <Link to="/Register/depot" onClick={() => this.props.close()}>
                  <div className="RoleModalBox">
                    <div>
                      <div>Depot Manager</div>
                    </div>
                    <div>
                      <span class="material-icons">chevron_right</span>
                    </div>
                  </div>
                </Link>
                <br />
              </Col>
            </Row>
            <Link
              to="ContactUs"
              onClick={() => this.props.close()}
              className="RoleModaltxt3"
            >
              Contact Support
            </Link>
          </div>
        </div>
      </Modal>
    );
  }
}
export default RoleModal;
