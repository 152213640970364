import { Button, Modal } from "antd";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import bg from "../Assets/Images/announcemntnew22.png";
import InfoModal from "./InfoModal";

import "./AnnoucmentModal.css";

function AnnoucmentModal() {
  const navigateHtml = (screen) => {
    window.location.href = screen;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row>
        {/* <Col sm="2"></Col> */}
        <Col sm="12" xs="12" md="10" lg="12" xl="12">
          <img
            src={bg}
            onClick={showModal}
            style={{ width: "100%", height: "250px" }}
          />
        </Col>
        {/* <Col sm="2"></Col> */}
      </Row>
      {/* {isModalVisible ? (
        <InfoModal open={isModalVisible} setOpen={setIsModalVisible} />
      ) : null} */}
      <Modal
        title={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        // footer={false}
      >
          <h5>Dear Customer,</h5>
          <p>
            As part of our continuous growth and progress, we have changed our
            booking process. Previously parcels and packages were booked based on
            the weight on our system and a standard price was given according to
            that weight. This created a massive error between our shipping volume
            to actual parcels shipped. Due to this we have rectified this problem
            and implemented a new booking procedure.
          </p>
          <h5>The new booking procedure</h5>
          <p>
            For Multi packages going to one address- Group all parcels in one box
            and use only one label and booking. For example, 5 packages weighing
            5kg each and going to one address should be in one box weighing 25kg.
          </p>
          <h5>Single parcels remain the same.</h5>
          <h5>Our prices are not affected</h5>
          <p>Sorry for any inconvenience caused by this change.</p>
      </Modal>
    </>
  );
}

export default AnnoucmentModal;
