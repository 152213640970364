import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import API from "../config/API";
import { message } from "antd";
import RoleModal from "./RoleModal";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", reg: false };
  }
  sendSubscription1 = () => {
    alert("sendSubscription");
  };
  myChangeHandler = (event) => {
    this.setState({ email: event.target.value });
  };
  sendSubscription = (event) => {
    event.preventDefault();
    let data = {
      email: this.state.email,
    };
    fetch(process.env.REACT_APP_API_KEY + API.POST_SUBSCRIPTION, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ email: null });
        message.success("Subscriptions sended successfully. Thank you!");
      })
      .catch((error) => {
        message.error("Error while Sending Subscribtion");
      });
  };
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col sm="3" xs="12">
              <h5 className="logotxt">About Us</h5>
              <Link to="/careers">
                <div className="footerlinks">Careers</div>
              </Link>
              <Link to="/contact-us">
                <div className="footerlinks">Contact Us</div>
              </Link>
              <Link to="/privacy">
                <div className="footerlinks">Privacy Policy</div>
              </Link>
              <Link to="/terms-and-conditions">
                <div className="footerlinks">Terms and Conditions</div>
              </Link>
              <Link to="/Help-Support/Getting-it-sorted">
                <div className="footerlinks">Getting it sorted</div>
              </Link>
              <br />
            </Col>
            <Col sm="2" xs="12">
              <h5 className="logotxt">Quick Links</h5>
              <span onClick={() => this.setState({ reg: true })}>
                <div className="footerlinks">Register</div>
              </span>
              <Link to="/login">
                <div className="footerlinks">Login</div>
              </Link>

              <Link to="/pricing">
                <div className="footerlinks">pricing</div>
              </Link>
              <Link to="/carrier-policy">
                <div className="footerlinks">Carrier Policy</div>
              </Link>
              <Link to="/partners">
                <div className="footerlinks">Partners</div>
              </Link>
              <br />
            </Col>
            <Col sm="3" xs="12">
              <h5 className="logotxt">Contact Us</h5>
              <div>
                <a className="footerlinks" href="tel:015549629">
                  +353-(01)-554-9629
                </a>
              </div>
              <div>
                <a className="footerlinks" href="mailto:info@parcel-king.com">
                  info@parcel-king.com
                </a>
              </div>
              <h5 className="logotxt1"> &#x1f3e2; Blanchardstown Office:</h5>
              <div className="footerlinks" style={{ fontSize: 12 }}>
                Unit 3, Damastown Way, Damastown Industrial Park, Dublin 15. D15
                XW7K
              </div>
              <h5 className="logotxt1"> &#x1f3e2; Galway Office: </h5>
              <div className="footerlinks" style={{ fontSize: 12 }}>
                Unit 4, Racecourse Technology Park, <br /> Ballybrit,
                <br /> Galway, H91 T3VC
              </div>
            </Col>
            <Col sm="4" xs="12">
              <h5 className="logotxt">Follow Us</h5>
              <div className="socialmeadialinksBox">
                <a
                  href="https://www.facebook.com/Parcel-King-101846268583306/?ref=page_internal"
                  className="socialmeadiaitems"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/parcelking1/"
                  className="socialmeadiaitems"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://twitter.com/ParcelKing3"
                  className="socialmeadiaitems"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/75411263/admin/"
                  className="socialmeadiaitems"
                >
                  <LinkedInIcon />
                </a>
              </div>
              <br />
              <div className="logotxt">Subscribe For Updates & Insights</div>
              <br />
              <form
                className="subscriptionform"
                name="subscription"
                onSubmit={this.sendSubscription}
              >
                <input
                  type="email"
                  placeholder="Your Email"
                  required
                  onChange={this.myChangeHandler}
                  className="subscriptioninputbox"
                  value={this.state.email}
                />
                <input
                  type="submit"
                  className="subscriptionsubmit"
                  value="SEND"
                  disabled={this.state.email == ""}
                />
              </form>
              <br />
              <h5 className="logotxt">Download Now</h5>
              <Row>
                <Col sm="4" xs="12">
                  <a href="https://play.google.com/store/apps/details?id=com.route2go">
                    <img
                      src={require("../Images/gplay.svg")}
                      className="footerimag"
                      alt="logo"
                    />
                  </a>
                </Col>
                <Col sm="4" xs="12">
                  <a href="https://apps.apple.com/in/app/parcelking/id1493371396">
                    <img
                      src={require("../Images/appsto.svg")}
                      className="footerimag"
                      alt="logo"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <div className="footercopy">
            Copyright ©2020 parcel-king.com. All rights reserved
          </div>
        </Container>
        <RoleModal
          visible={this.state.reg}
          close={() => this.setState({ reg: false })}
        />
      </footer>
    );
  }
}
export default Footer;
