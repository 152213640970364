import { Col, Row } from "antd";
import React from "react";

const partners = [
  {
    id: 1,
    name: "FEDEX",
    logo: "https://logos-world.net/wp-content/uploads/2020/04/FedEx-Emblem.jpg",
    location: "",
    alt: "FEDEX Business Partner",
    url: "https://www.fedex.com/en-in/home.html",
    css: "gls-logo",
  },
  {
    id: 2,
    name: "UPS",
    logo: "https://www.ups.com/assets/resources/images/UPS_logo.svg",
    location: "",
    alt: "UPS Business Partner",
    url: "https://www.ups.com/ie/en/Home.page",
    css: "gen-logo",
  },
  {
    id: 3,
    name: "Amazon",
    logo: "https://d2gt4vueeig3bq.cloudfront.net/static-assets/icons/logo-desktop.png",
    location: "",
    alt: "Amazon Logistics Business Partner",
    url: "https://logistics.amazon.com",
    css: "amazon-logo",
  },
  {
    id: 4,
    name: "AnPost",
    logo: "https://www.anpost.com/build/images/logos/anPostLogo.svg",
    location: "",
    alt: "An Post Business Partner",
    url: "https://www.anpost.com",
    css: "an-post-logo",
  },
];
export default function PartnersItem() {
  return (
    <Row gutter={16} className="m-auto">
      {partners.map((item, index) => (
        <Col
          key={item.id}
          sm={3}
          xs={{ span: 12 }}
          lg={{ span: 4 }}
          className="gutter-row m-auto"
        >
          <div className={`${item.css} partner-logo`}>
            <a href={item.url} target="_blank">
              <img src={item.logo} alt={item.alt} className="Homepartenerimg" />
            </a>
          </div>
          <br />
        </Col>
      ))}
    </Row>
  );
}
