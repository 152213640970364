import React from "react";
import HomeHeader from "../../Components/HomeHeader";
import Footer from "../../Components/Footer";
import { Container } from "react-bootstrap";
import "./pricing.module.css";
import MainHeader from "../../Components/MainHeader";
import OtherHeader from "../../Components/OtherHeader";
import parcelkingprice from "../../Assets/Images/pricechange.jpg"

class Pricing extends React.Component {
  render() {
    return (
      <div className="homeContainer">
        {" "}
        <OtherHeader />
        <div className="Terms-Header">
          <Container className="TermsheaderBox">
            <br />
            <h3 className="TermsheaderTxt">Terms and Conditions</h3>
          </Container>
        </div>
        <br />
        <div className="TermsContainer">
          <div className="Terms-Header">
            <Container className="TermsheaderBox">
              <br />
              <h3 className="TermsheaderTxt">
                Parcel-King Pricing for Collect, Deliver or Post
              </h3>
            </Container>
          </div>

          <Container className="w-100 m-auto">
            <h5 className="privacySubHeading">Parcel-King National Rates</h5>
            <div className="privacytxt">
              Below are the pricings for National Wides Courier rates.
            </div>
            <br />
            <img
              className="card"
              style={{ width: "80%", padding: "20px", margin: "auto" }}
              src={
                // "https://parcelking.s3.eu-west-1.amazonaws.com/DublinIreland.png"
                parcelkingprice
              }
            />
          </Container>
          <Container className="w-100 m-auto">
            <h5 className="privacySubHeading">
              Parcel-King International Rates
            </h5>
            <div className="privacytxt">
              Below are the pricings for International Courier rates. If you do
              not find your country on the list, please contact office to know
              more.
            </div>
            <br />
            <img
              className="card"
              style={{ width: "80%", padding: "20px", margin: "auto" }}
              src={
                "https://parcelking.s3.eu-west-1.amazonaws.com/International.png"
              }
            />
            <img
              className="card"
              style={{ width: "80%", padding: "20px", margin: "auto" }}
              src={
                "https://parcelking.s3.eu-west-1.amazonaws.com/International2.png"
              }
            />
            {/* <div className="privacytxt">
              Global Courier Rates Based on Region of Destination
            </div>
            <img
              className="card"
              style={{ width: "80%", padding: "20px", margin: "auto" }}
              src={
                "https://parcelking.s3.eu-west-1.amazonaws.com/GlobalRates.png"
              }
            />
            <img
              className="card"
              style={{ width: "80%", padding: "20px", margin: "auto" }}
              src={
                "https://parcelking.s3.eu-west-1.amazonaws.com/GlobalCountries.png"
              }
            /> */}
            <br />
          </Container>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Pricing;
