/* App config for apis*/
const API = {
  REPORT_SERVER: "https://report.parcel-king.com",
  LOGIN: "auth/weblogin", //admin, contractors, drviers login
  REGISTER: "auth/register", //admin, contractors, drviers registeration
  REFRESH_USER: "users/refresh", // if any user update actions happend reload and save data in redux store

  HOME_COUNTS: "web/home", //website Home-screen statistcs and other details
  SEND_ENQUIRY: "enquiry/new-enquiry", //website contact-screen send contact form
  POST_SUBSCRIPTION: "newsletter/post-subscription", //website footer email subscription input send
  LIST_ENQUIRY: "enquiry/list-enquiry", // admin view contact enquiries in website

  THIRDPARTY_PACKAGE: "thirdparty-pakages", //get thirdparty packages
  THIRDPARTY_CUSTOMERS: "thirdparty_customers", //get thirdparty customers
  THIRDPARTY_CUSTOMERS_SENT_MAIL: "thirdparty-pakages/createandsentMail", //get thirdparty customers
  THIRDPARTY_PACKAGE_DELETE: "thirdparty-pakages/remove/", //delete thirdparty packages

  GET_DRIVERS_DROPDOWN: "driver/dropdown/", //admin and contractors all forms dropdowns driver picker /:adminid or contractorid
  GET_USERS_DROPDOWN: "users/dropdown/", //admin only forms dropdowns user picker
  GET_RETAILER_DROPDOWN: "retailer/dropdown/", //admin only forms dropdowns retailer picker

  GET_DETAILS: "dashboard/details/", // admin , contractor , driver , user , retailer full summary and details
  CLOCK_INOUT: "clockinout/", //admin, contractors, drviers log working time
  GET_USERS: "users/all-users/", //admin and contractors users-screen. /:adminid or contractorid
  GET_CONTRACTORS: "users/all-contractors", // admin contractors-screen list all contractors

  GET_PAY_TOKEN: "payment/get-token", //br  aintree payemnt tocken generated nodejs pass details in this API
  PAY_NOW: "payment/pay-now", //braintree payemnt nodejs pass details in this API
  STRIPE_PAY_BOOKING: "stripe/booking", // not using
  STRIPE_PAY_INVOICE: "stripe/invoice", // not using
  SRIIPE_3D_TOKEN: "stripe/token", // payment creating user
  STRIPE_BOOKING_SUCCESS: "stripe/bookSuccess", // after payment success save booking in database
  STRIPE_INVOICE_SUCCESS: "stripe/invoiceSuccess", // after payment success save invoice in database
  STRIPE_REFUND: "stripe/refund", // refund amount and update booking and payment table
  STRIPE_PAY_ALL_DUE: "stripe/paymentSuccess", // pay all due amount

  LIST_FILTER_BOOKING: "userBooking/filter/", // filter bookings by date
  GET_BOOKING_PRICE: "userBooking/price", //any user get package transfer price and time in website booking
  GET_DEPOTBOOKING_PRICE:"depotbooking/price",
  NEW_BOOKING: "userBooking/new", //new booking for any user in website
  GET_BOOKING_LIST: "userBooking/all", // admin and contractors list all bookings
  GET_BOOKING_DETAILS: "userBooking/details/", // admin and contractors show all details in abooking pass /:booking id
  UPDATE_BOOKING: "userBooking/update", // admin and contractors update booing deatils
  UPDATE_BOOKING_STATUS: "/bookings/updateBooking",
  DELETE_BOOKING: "userBooking/delete/", // admin and contractors permenent delete booking /:booing id
  DELETE_BOOKING_STATUS: "userBooking/delete-status/", // admin and user delete booking if status is pending
  ASSIGN_BOOKING_DRVIER: "userBooking/assign-driver", // admin assign a driver on in booking
  PAY_BOOKING_INVOICE: "userBooking/pay-invoice", // user pay single item
  EDIT_BOOKINGS_PAYMENT: "userBooking/edit-payment", //admin add discount in booking
  BULK_BOOKING: "userBooking/bulk-booking", //users and retailers book in single step
  BOOKING_FORCE_PAID: "userBooking/payment", // admin update booking paid
  COLLECT_DELIVERY_BOOK: "userBooking/collect_book", // users and retailers book a collect and delivery include payment
  BOOKING_CSV_UPLOAD: "userBooking/upload-csv", //users and retailer upload csv to bulk booking
  BOOKING_CSV_PRICE: "userBooking/csv_price",
  OPEN_BOOKING: "userBooking/openBooking", //without login booking
  PAID_BOOKING: "userBooking/paid-booking", // pay and contiue

  //user details summary
  GET_USER_BOOKING: "userBooking/user/", // users list all bookings
  GET_USER_PAYMENTS: "payment/user-payments/", //pass user id and /:userId list all bookings
  GET_USER_RECEIPTS: "payment/user-receipts/", //pass user id and /:userId list all bookings
  GET_USER_INVOICES: "",
  //GET_USER_PAYMENTS: "",
  //user details and summary end
  //driver details and summary
  GET_DRIVERS_LIST: "driver/list/", //admin and contractors drivers-screen. /:adminid or contractorid
  GET_DRIVERS_DETAILS: "driver/details/", //admin and contractors driversDetails-screen. /:driver id
  GET_DRIVERS_HOLIDAYS: "driver/holidays/", //admin and contractors driversDetails-screen holidays list. /:driver id
  GET_DRIVERS_CLOCKIN: "driver/clockin/", //admin and contractors driversDetails-screen clock in out list. /:driver id
  GET_DRIVERS_JOURNEYS: "driver/journeys/", //admin and contractors driversDetails-screen journeys list. /:driver id
  GET_DRIVERS_VEHICLE_CHECK: "driver/vehicleReports/", //admin and contractors driversDetails-screen vehicle report list. /:driver id
  GET_DRIVERS_VEHICLE_SERVICES: "driver/vehicleRepairs/", //admin and contractors driversDetails-screen vehicle repair list. /:driver id
  GET_DRIVERS_INVOICES: "driver/invoices/", //admin and contractors driversDetails-screen invoice list. /:driver id
  SET_BOOKING_DRIVERS: "driver/setbookingdriver", //admin and contractors set booking attend drivers
  SET_DRIVER_APP_SETTINGS: "driver/setdriverapp", //admin set dirver app settings for drivers
  //end user details and summary

  NEW_CREDIT: "credits/new", // user submit credit form to get credit
  SET_BONUS: "credits/bonus", // admin give bonus discounts in total credits

  LIST_SHOPIFY_ORDER: "shopify/list/", //admin,contractor,users set shopify orders list
  SET_SHOPIFY: "shopify/set", // admin,contractor,users set shopify orders credintials save
  EDIT_SHOPIFY: "shopify/edit", // admin,contractor,users edit shopify orders credintials save
  DELETE_SHOPIFY: "shopify/delete", // admin,contractor,users delete shopify orders credintials
  CALCULATE_SHOPIFY: "shopify/calculate", // admin,contractor,users delete shopify orders credintials

  LIST_CHARGESHEET: "chargesheet/all", // admin list all booking charges and details
  DETAILS_CHARGESHEET: "chargesheet/details/", // admin show details booking charges and details
  ADD_CHARGESHEET: "chargesheet/add", // admin add booking charges and details
  UPDATE_CHARGESHEET: "chargesheet/update", //admin update booking charges and details
  DELETE_CHARGESHEET: "chargesheet/delete/", // admin delete booking charges and details

  LIST_ALL_INVOICES: "Invoice/list/", // admin list all invoices  /:user or driver
  LIST_USER_INVOICES: "Invoice/user/", // driver and users list invoices by id in lggined user
  GENERATE_INVOICE: "Invoice/generate-invoice",
  ADD_INVOICE: "Invoice/add-Invoices", //admin and contractors create new
  UPDATE_INVOICE: "Invoice/update-Invoices", //admin and contractor update invoice
    EMAIL_INVOICE: "Invoice/emailInvoice", // admin and contractors send invoice to mail
  DELETE_INVOICES_ID: "Invoice/delete-Invoices/", //admin delete invoice
  GET_INVOICES_DETAILS: "Invoice/get-InvoicesDetails", //admin show invoice details
  PAY_INVOICE: "Invoice/pay", // user pay invoice amount

  LIST_ALL_RETAILER: "retailer/list/", // admin & contractors list all retailers available

  SEND_MAIL: "Mail/send_mail", // admin send bulk mail to users

  PAYROLL_USERS: "payroll/users", //admin list all users in all roles
  PAYROLL_DETAILS: "payroll/details/", // admin show specifc user payroll details
  PAYROLL_SAVE: "payroll/save", // admin save payroll and update
  PAYROLL_BY_USER: "payroll/user/", // user list payrolls

  LIST_ROLS: "role/list", //admin list user rols
  ADD_ROLS: "role/add", //admin add user rols
  UPDATE_PRIVILAGES: "role/update", // --pending

  LIST_MODULE: "modules/list", //admin list modules
  ADD_MODULE: "modules/add", //admin add modules
  DELETE_MODULE: "modules/delete/", // --pending

  APP_TRACK: "apptrack/track/", //driver app jounreys
  APP_JOURNEY_DETAILS: "apptrack/", //driver app jounreys
  APP_LIVE_TRACKING: "apptrack/", //driver app jounreys

  GET_USER_STATEMENTS: "userstatement/get",
  GET_DEPOT_STATEMENTS: "/getstatement",
  SEND_USER_STATEMENTS: "userstatement/send",

  LOCATION_TRACK: "location/",

  ADDRESS_LIST: "userAddress/list/",
  ADDRESS_DETAILS: "userAddress/details/",
  ADDRESS_ADD: "userAddress/add",
  ADDRESS_DELETE: "userAddress/delete/",
  ADDRESS_UPDATE: "userAddress/update/",

  EMAIL_LIST: "usermails/list/",
  EMAIL_ADD: "usermails/add",
  EMAIL_DELETE: "usermails/delete/",
  EMAIL_UPDATE: "usermails/update",

  GET_PICKUP: "pickup/all-pickups-web",
  GET_DELIVERY: "delivery/all-delivery-web",
  GET_TRANSACTIONS: "payment/history",
  GET_USER_DETAILS: "user/",

  GET_PICKUP_DETAILS: "pickup/details",
  GET_DELIVERY_DETAILS: "delivery/details",
  GET_USER_PICKUPS: "pickup/web-pickups",
  GET_USER_DELIVERIES: "delivery/user",

  ADD_USER: "users/add-user",
  ADD_DRIVER: "auth/register",

  GET_TIMESHEET: "TimeSheet/get-timesheet/", //:id/:date
  ADD_TIMESHEET_TASK: "TimeSheet/add-task",
  DELETE_TIMESHEET_TASK: "TimeSheet/delete-task/", //:id
  UPDATE_TIMESHEET_TASK: "TimeSheet/update-task",

  UPDATE_USER: "user/update-user",
  GET_PACKAGE: "anpost/getPackageDetails",
  GET_PACKAGE_DATE: "anpost/getPackageByDate",
  GET_PACKAGE_DELIVERY: "anpost/getPackageByDelivery",
  UPLOAD_PACKAGE: "anpost/addPackageByCode",

  GET_ALL_JOURNEY: "shifts/getall-shift/",
  GET_JOURNEY_DRIVERS: "shifts/getFree-Driver",
  GET_JOURNEY_PACKAGES: "shifts/getNew-Packages",
  GET_JOURNEY_DETAILS: "shifts/Details-shift/",
  ADD_JOURNEY: "shifts/create-shift",
  UPDATE_JOURNEY: "shifts/Edit-shift",
  DELETE_JOURNEY: "shifts/delete-shift/",

  GET_PICKUPDELIVERY: "pickupdelivery/all-pickupdelivery/",
  ADD_PICKUPDELIVERY: "pickupdelivery/add-pickupdelivery",
  EDIT_PICKUPDELIVERY: "pickupdelivery/update-pickupdelivery",
  DELETE_PICKUPDELIVERY: "pickupdelivery/delete-pickupdelivery/",

  ALL_BOOKINGS: "bookings/all-bookings",
  BOOKINGS: "bookings/user-bookings/",
  ADD_BOOKINGS: "bookings/add-bookings",
  EDIT_BOOKINGS: "bookings/edit-bookings",
  DELETE_BOOKINGS: "bookings/delete-bookings/",

  GET_VEHICLE_LIST: "Vehicles/all-vehicles/",
  GET_VEHICLE_DETAILS: "Vehicles/vehicles-details/",
  ADD_VEHICLE: "Vehicles/add-vehicles",
  EDIT_VEHICLE: "Vehicles/update-vehicles",
  DELETE_VEHICLE: "Vehicles/delete-vehicles/",

  GET_ALL_VEHICLEREPORT_LIST: "Vehicles/AllvehicleReports/",
  GET_VEHICLEREPORT_BY_VEHICLE: "Vehicles/vehicleReportsById/",
  GET_VEHICLEREPORT_DETAILS: "Vehicles/ReportsDetails/",
  ADD_VEHICLEREPORT: "Vehicles/add-vehiclesReport",
  EDIT_VEHICLEREPORT: "Vehicles/update-vehiclesReport",
  DELETE_VEHICLEREPORT: "Vehicles/delete-vehiclesReport/",

  GET_VEHICLEREPAIR_LIST: "vehicleRepair/all-Repairs/",
  GET_VEHICLEREPAIR_DETAILS: "vehicleRepair/repair-Details/",
  ADD_VEHICLEREPAIR: "vehicleRepair/add-Repair",
  EDIT_VEHICLEREPAIR: "vehicleRepair/update-Repair",
  DELETE_VEHICLEREPAIR: "vehicleRepair/delete-Repair/",

  GET_JOBS_LIST: "careers/all/",
  GET_JOB_DETAILS: "careers/details/",
  APPLY_JOB: "careers/apply/",
  GET_JOBAPPLY_DETAILS: "careers/apply-details/",
  GET_JOB_DETAILS_ALL: "careers/details-all/",
  ADD_JOB: "careers/add",
  EDIT_JOB: "careers/update",
  DELETE_JOB: "careers/delete/",

  GET_HOLIDAY_LIST: "holidays/list/",
  GET_HOLIDAY_DETAILS: "holidays/details/",
  ADD_HOLIDAY_LEAVES: "holidays/add",
  EDIT_HOLIDAY_LEAVES: "holidays/edit",
  DELETE_HOLIDAY_LEAVES: "holidays/delete/",

  GET_ALL_PROPERTY: "driver/getAllProperty/",
  ADD_PROPERTY: "driver/addupdateproperty",
  UPDATE_PROP_SETTING: "driver/addupdaterates",
  GET_RATES: "driver/getrates/",

  GET_INVENTORY: "inventory/getitems/",
  ADD_INVENTORY: "inventory/add-item",
  DELETE_INVENTORY: "inventory/delete-item/",
  UPDATE_INVENTORY: "inventory/update-item",
  GET_INVENTORY_DETAILS: "inventory/getItemDetails/",

  CUSTOMER_STATEMENT: "Invoice/statement/",
  ISSUE_STATEMENT: "Invoice/issuestatement",

  //user list and details form admin and contractors only
  GET_USERS_LIST: "users/all-users/", //GET ?page=1&take=10
  POST_USERS: "userdetails/add", //POST
  PUT_USERS_DETAILS: "userdetails/edit/", //PUT
  GET_USERS_DETAILS: "userdetails/details/", //GET id
  GET_USERS_DETAILS_STATITICS: "userdetails/statics/", //GET id
  GET_USERS_DETAILS_PAYMENTS: "userdetails/payments/", //GET id
  GET_USERS_DETAILS_INVOICES: "userdetails/invoices/", //GET id
  GET_USERS_DETAILS_STATEMENTS: "userdetails/statement/", //GET id
  GET_USERS_DETAILS_HOLIDAYS: "userdetails/holidays/", //GET id
  GET_USERS_DETAILS_CLOCKIN: "userdetails/clockin/", //GET id
  GET_USERS_DETAILS_JOURNEYS: "userdetails/journey/", //GET id
  GET_USERS_DETAILS_VEHICLECHEK: "userdetails/vehiclecheck/", //GET id
  GET_USERS_DETAILS_VEHICLEINSPECT: "userdetails/vehicleinspec/", //GET id
  GET_USERS_DETAILS_TRACK: "userdetails/",
  GET_USERS_DETAILS_LOGS: "userdetails/logs/", //GET id
  PUT_USERS_DETAILS_CREDIT: "userdetails/", //PUT
  PUT_USERS_DETAILS_SHOPIFY: "userdetails/", //PUT
  PUT_USERS_DETAILS_SETTINGS: "userdetails/", //PUT

  GET_USERS_DETAILS_BOOKINGS: "userdetails/bookings/", //GET id
  PUT_USERS_DETAILS_BOOKINGS_PAID: "userdetails/bookings-paid/", //PUT array
  //end user list

  //vehicle Inspection
  GET_VEHICLE_INSPECTION: "vehicleInspection/list", //GET
  ADD_VEHICLE_INSPECTION: "vehicleInspection/add", //POST
  DELETE_VEHICLE_INSPECTION: "vehicleInspection/delete/", //GET
  UPDATE_VEHICLE_INSPECTION: "vehicleInspection/update", //POST
  GET_VEHICLE_INSPECTION_DETAILS: "vehicleInspection/details/", //GET id
  //end vehicle Inspection

  //driver invoices
  GET_DRIVERS_INVOICE: "driverInvoices/list", //GET
  ADD_DRIVERS_INVOICE: "driverInvoices/createInvoices", //POST
  LIST_DRIVERS_INVOICE: "driverInvoices/listInvoices", //GET

  //end driver invoices

  //invoice by filter
  INVOICE_BY_FILTER: "/Invoice/invoiceByFilter", //post
  BOOKING_BY_FILTER: "/bookings/bookingsWithFilter",
  BOOKING_WITHOUT_FILTER: "/bookings/getAllBookings",
  //get enquiry list by pagination
  GET_ENQUIRY: "/Enquiries",

  PUT_USER_CREDITS: "/userCredits",

  DELIVERY_PICKUPS_BY_BOOKING_ID: "packages/allByBookingId/",

  //get depot list
  GET_DEPOT_LIST: "depot/list",
  GET_BY_SCAN_DATA: "bookingStatuses/", //get booking status by scandata
  DELIVERY_PICKUPS_BY_BOOKING_ID: "packages/allByBookingId/",
  DOWNLOAD_DATAS: "bookings/user-create-pdf-bydatefilter",
  DOWNLOAD_POD: "/thirdparty-pakages/generatePODByDateFilter",
  DOWNLOAD_POD_BOOKING: "/bookings/generatePODBooking",
  DEPOT_MANAGERS_DATA: "users/depotmanager",
  
  //booking package update
  PUT_UPDATE_BOOKING: "userBooking/editpackage",

  //get depotthirdpartybookings price 
  GET_DM_PRICING_LIST: "dmPricing/list",
  ADD_DM_PRICE_SLAB: "dmPricing/add",

  //get primline pricing
  GET_PRIMELINE_PRICES_LIST : "PrimelineDmPricing/list",
  ADD_PRIMELINE_PRICE_SLAB : "PrimelineDmPricing/add",

  // get balance
  GET_BALANCE_AND_USER: "/bookings/findAllByUserBalance"
};
export default API;
