import React, { useState } from "react";
import OtherHeader from "../../Components/OtherHeader";
import Footer from "../../Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { Form, Input, Card, message } from "antd";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Add } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./styles";
import "./styles.css";
import API from "../../config/API";
const { TextArea } = Input;
function Contactus() {
  const [loading, setLoading] = useState(false);
  const formRef = React.createRef();

  const onFinish = (values) => {
    let obj = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: values.phone,
      message: values.message,
    };
    setLoading(true);
    fetch(process.env.REACT_APP_API_KEY + API.SEND_ENQUIRY, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        if (responseJson.status) {
          message.success("Your Enquiry sended successfully.");
          formRef.current.resetFields();
        } else {
          message.error("Error sending Try agin !");
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error("Error sending Try agin !");

        setLoading(false);
      });
  };

  const contactOptions = [
    {
      no: "01",
      options: "Alicia Bot",
      desp: "Alicia Bot is always available to help you find information about some of our products and services and to track your post too! You can ask him a question now on our help page.",
    },
    {
      no: "02",
      options: "Web Chat",
      desp: "Webchat is the fastest way to get in touch directly with our Customer Service Team. The Team are available 9am to 5pm Monday to Friday and 9am to 2pm on a Saturday. The queue time for our Webchat Service is usually very low but during busy periods the wait time may increase. Our Team are working very hard to keep this time to a minimum.",
    },
    {
      no: "03",
      options: "Social",
      desp: "Another quick way to get in touch directly with our Customer Services Team is to send a message on Facebook using private messenger or send a direct message on Twitter @ParcelKing3 or Connect us through whatsapp. The response time is usually very fast but during busy periods this may increase. Our Team are working very hard to ensure you will receive a reply quickly",
    },
    {
      no: "04",
      options: "Other ways to contact us",
      desp: "The contact us form gives you the option to send an email enquiry to the Customer Services Team. This option is different to our live Webchat and Social channels. You will receive an enquiry number in response to your email and the Team will need time to investigate and reply to you. This may take a number of days so please bear with us as we work through enquiries. ",
    },
  ];

  return (
    <div>
      <OtherHeader />
      <Container className="ContactMainBox">
        {/* <Fade top>
          <br />
          <Card>
            <Row className="ContactCardBox">
              <Col sm="2" xs="12">
                <div className="ContactImgBox">
                  <img
                    src={require("../../Images/covid.png")}
                    alt="ContactCardBox"
                  />
                </div>
              </Col>
              <Col sm="10" xs="12">
                <div className="ContactMainBox3">
                  We are experiencing a high volume of calls and webforms to our
                  call centre. Due to Covid 19 and measures we have taken on
                  social distancing, it has impacted our response times. As we
                  are trying to assist customers with the most urgent enquiries,
                  we ask that you only contact us by phone or webform if you
                  cannot find an answer on our website.
                </div>
              </Col>
            </Row>
          </Card>
        </Fade> */}
        <br />

        <Fade left>
          <h2 className="help-supprt-contact-txt1">Contact us queue</h2>
          <p className="help-supprt-contact-txt2">
            Check below to see what the best way to contact us is and how long
            you can expect to wait until we can respond to your query.{" "}
          </p>
          <div>
            {contactOptions.map((item) => (
              <Accordion
                style={{
                  boxShadow: "0px 0px 0px rgba(0, 0, 0,0)",
                  borderBottom: "1px solid #DCDCDC",
                  padding: 10,
                }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  expandIcon={<Add style={{ color: "#98fb98 !important" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="home-txt12">{item.options}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <h6 className="home-txt9">{item.desp}</h6>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Fade>

        <br />
        <br />
        <br />
        <Fade right>
          <div>
            <h2 className="help-supprt-contact-txt1">
              {" "}
              Popular customs queries
            </h2>
            <div>
              <Accordion
                style={styles.Accordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  expandIcon={<Add style={{ color: "#98fb98 !important" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="home-txt12">
                    Why has my postal item been returned to sender?
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="CustomerAdvocateForm-list">
                    <li>
                      If your item has been returned to sender or you are
                      awaiting an update on your item, it is likely that the
                      electronic customs declaration made by the retailer/sender
                      for your item may have been insufficient or invalid
                      according to Customs regulations. An Post cannot amend
                      customs declaration so items with incomplete data must be
                      returned.{" "}
                    </li>
                    <li>
                      The goods within your parcel may be in a category of goods
                      treated as prohibited or restricted by Irish Revenue.{" "}
                    </li>
                    <li>
                      For non restricted/prohibited items, you will need to
                      refer to the sender and request that they submit a customs
                      declaration with full details when resending your item.
                      Our TARIC code finder helps retailers understand the
                      required information needed.{" "}
                    </li>
                    <li>
                      Your item will be returned to the sender if the charges
                      are not paid by the “pay by date".{" "}
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={styles.Accordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  expandIcon={<Add style={{ color: "#98fb98 !important" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="home-txt12">
                    Why do I need to pay customs charges before An Post delivers
                    my item?
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="CustomerAdvocateForm-list">
                    <li>
                      An Post collect the customs charges on your behalf that
                      are charged by Revenue on items delivered to Ireland from
                      non-EU countries. In order for your parcel to be processed
                      and delivered, these charges must be paid.{" "}
                    </li>
                    <li>
                      If VAT or customs charges are due on the item, where
                      possible, we will contact you by post, text or email. You
                      can then pay the charges through our website or at your
                      local Post Office. We will deliver your item as soon as
                      possible after payment has been received.
                    </li>
                    <li>
                      If there are no VAT or customs charges due on the item,
                      then the item will be delivered as normal.
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={styles.Accordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  expandIcon={<Add style={{ color: "#98fb98 !important" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="home-txt12">
                    My item has not moved, why is this?
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="CustomerAdvocateForm-list">
                    <li>
                      Compliant electronic customs data must be supplied for all
                      incoming non-EU items by the sender. Many smaller, more
                      specialist British retailers are simply not aware of these
                      requirements and/or have not put in place the necessary
                      online shopping systems for selling to Ireland, leading to
                      Customs delays or returns when these parcels arrive in
                      Ireland. Please hold tight, while we work to address this
                      and ongoing queries.
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={styles.Accordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  expandIcon={<Add style={{ color: "#98fb98 !important" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="home-txt12">
                    How can I pay the customs charge?
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="CustomerAdvocateForm-list">
                    <li>Your customs charge can be paid either:</li>
                    <li>
                      Online, using your credit/debit card and the Customs
                      reference number detailed on the Postcard/SMS/Email; or
                    </li>
                    <li>
                      at any post office, using your credit/debit card or cash.
                      Please bring the email or the card you received in the
                      post with you to the Post Office.
                    </li>
                    <li>
                      Once your payment is complete, we will arrange for your
                      item to be delivered as soon as possible.
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              <Accordion
                style={styles.Accordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  expandIcon={<Add style={{ color: "#98fb98 !important" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="home-txt12">
                    I received a notification via post/SMS/email from
                    Parcel-King notifying me that Parcel-King are unable to
                    deliver my item until customs charges are paid. Why am I
                    being charged customs charges?
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="CustomerAdvocateForm-list">
                    <li>
                      If you buy goods from outside the EU  you may have to pay
                      customs charges (Customs Duty, Excise Duty, VAT) to Irish
                      Revenue.{" "}
                    </li>
                    <li>
                      Parcel-King collect these customs charges from you and pay
                      them to Irish Revenue on your behalf.
                    </li>
                    <li>
                      It is mandatory for Parcel-King to collect customs
                      charges, Parcel-King cannot deliver your items until these
                      charges have been paid.
                    </li>
                    <li>
                      A team of Irish Revenue’s Customs officials are based at
                      Parcel-King's Mail Centre and oversee the customs
                      clearance of imported goods.   
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              <Accordion
                style={styles.Accordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  expandIcon={<Add style={{ color: "#98fb98 !important" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="home-txt12">
                    How do I know the SMS/email message I received is legitimate
                    and not phishing?
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="CustomerAdvocateForm-list">
                    <li>
                      An Post will never send a message containing any links
                      when contacting you about the payment of customs charges.
                    </li>
                    <li>
                      Also, within a few days of receiving an An Post customs
                      SMS or email you will receive an official An Post postcard
                      detailing your customs charges (a customs charge
                      notification card to which specific Terms & Conditions
                      apply).
                    </li>
                    <li>
                      You can pay these charges online through our payment
                      channel. You will need your track & trace number and
                      reference number provided in the SMS/email/postcard.
                    </li>
                    <li>
                      Alternatively you can also pay the charges by bringing
                      your reference details to any post office.
                    </li>
                    <li>
                      If you are ever in doubt about scam messages we have
                      further information on our security page to help you
                      identify legitimate and scam messages.{" "}
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              <Accordion
                style={styles.Accordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  expandIcon={<Add style={{ color: "#98fb98 !important" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="home-txt12">
                    Why is there an An Post fee included in the charges?
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="CustomerAdvocateForm-list">
                    <li>
                      Customs charges arise on the importation of most items in
                      accordance with relevant laws.  An Post collects these
                      charges and pays them to Irish Revenue for you.
                    </li>
                    <li>
                      A customs admin fee of €3.50 is charged by An Post for
                      customs handling of items imported from countries outside
                      the EU. This fee is in addition to any duty or VAT payable
                      to Irish Revenue. The fee is unrelated to the amount of
                      duty and VAT payable and is in place to cover the
                      administrative costs of collecting and processing the
                      payment from the addressee.
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Fade>

        <br />
        <br />
        <br />
        <br />
        <h2 className="help-supprt-contact-txt3"> Contact Us</h2>
        <br />
        <Row>
          <Col sm="6" xs="12">
            <Fade right>
              <Form
                ref={formRef}
                name="basic"
                onFinish={loading ? null : onFinish}
              >
                <Row>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="firstname"
                      rules={[
                        {
                          required: false,
                          message: "Please input your First Name!",
                        },
                      ]}
                    >
                      <Input placeholder="First Name" style={styles.inputBox} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="lastname"
                      rules={[
                        {
                          required: false,
                          message: "Please input your Last Name!",
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" style={styles.inputBox} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" style={styles.inputBox} />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: false,
                      message: "Please input your phone!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Phone"
                    style={styles.inputBox}
                    type="number"
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Your Message!",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Your Message"
                    style={styles.inputBox}
                  />
                </Form.Item>
                <Form.Item>
                  <button type="submit" className="ContacSendButon">
                    {loading ? (
                      <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        size={20}
                        thickness={4}
                        style={{
                          color: "white",
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      />
                    ) : (
                      "Send Enquiry"
                    )}
                  </button>
                </Form.Item>
              </Form>
            </Fade>
          </Col>

          <Col sm="6" xs="12">
            <Fade left>
              <Card>
                <div className="ContactCardBox">
                  <div>
                    <img
                      src={require("../../Images/parcel.png")}
                      alt="ContactCardBox"
                    />
                  </div>

                  <div className="ContactCardBox1">
                    <div className="ContactCardBoxtxt1">
                      Post and Parcels enquiries
                    </div>
                    <a href="tel:015549629" className="ContactCardBoxtxt2">
                      Call +353-(01)-554-9629
                    </a>
                    <div className="ContactCardBoxtxt2">
                      Monday to Friday 9.00am-5.30pm
                    </div>
                  </div>
                </div>
              </Card>
            </Fade>

            <br />
            <Fade left>
              <Card>
                <div className="ContactCardBox">
                  <div>
                    <img
                      src={require("../../Images/bookings.png")}
                      alt="ContactCardBox"
                    />
                  </div>
                  <div className="ContactCardBox1">
                    <div className="ContactCardBoxtxt1">Booking enquiries</div>
                    <a href="tel:015549629" className="ContactCardBoxtxt2">
                      Call +353-(01)-554-9629
                    </a>
                    <div className="ContactCardBoxtxt2">
                      Monday to Friday 9.00am-5.30pm
                    </div>
                  </div>
                </div>
              </Card>
            </Fade>
            <br />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Contactus;
