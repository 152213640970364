import * as types from "../Action/Types";
const response = {
  loading: true,
  data: {},
};

const DetailsReducer = (state = response, action) => {
  switch (action.type) {
    case types.SAVE_DETAILS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }
    case types.CLEAR_DETAILS: {
      return {
        ...state,
        data: {},
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
export default DetailsReducer;
