import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Store, PersistedStore } from "./Redux/Store";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import FreshChat from "./Components/FreshChat/FreshChat";
import WhatsApp from "./Components/WhatsApp/WhatsApp";
require("dotenv");
Amplify.configure(awsExports);
ReactDOM.render(
  <Provider store={Store}>
    <PersistGate loading={null} persistor={PersistedStore}>
      <BrowserRouter>
        <App />
        {/* <FreshChat /> */}
        <WhatsApp />
      </BrowserRouter>
    </PersistGate>
  </Provider>, 
  document.getElementById("root")
);
serviceWorker.unregister();
