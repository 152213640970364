import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import DetailsReducer from "./DetailsReducer";
import PaymentReducer from "./PaymentReducer";
import AddressReducer from "./AddressReducer";
import RefreshReducer from "./RefreshReducer";
const RootReducer = combineReducers({
  User: LoginReducer,
  Details: DetailsReducer,
  payment: PaymentReducer,
  address: AddressReducer,
  refresh: RefreshReducer,
});
export default RootReducer;
