import { createStore, applyMiddleware } from "redux";
import RootReducer from "../Reducers";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "narc",
  storage,
  // blacklist: ["payment"],
};
const persistedReducer = persistReducer(persistConfig, RootReducer);
const Store = createStore(persistedReducer, applyMiddleware(thunk));
const PersistedStore = persistStore(Store);
export { Store, PersistedStore };
