import React, { useState } from "react";
import "./styles.css";
import { Modal } from "antd";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import Cookie from "../../Assets/Images/Cookie.svg";
const CookieModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(
    getCookieConsentValue() === undefined ? true : false
  );
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  return (
    <>
      <Modal visible={isModalVisible} footer={false} centered onCancel={handleCancel} closable={false}>
        <CookieConsent
          debug={true}
          disableStyles
          buttonText="Accept"
          buttonStyle={{
            color: "#000",
            fontSize: "15px",
            padding: "10px 30px",
            backgroundColor: "#ffc400",
            border: "1px solid #ffc400",
            borderRadius: "10px",
          }}
          declineButtonStyle={{
            padding: "10px 30px",
            backgroundColor: "#ff9494",
            fontSize: "15px",
            margin: 20,
            marginBottom: 10,
            borderRadius: "10px",
            border: "1px solid #ff9494",
          }}
          enableDeclineButton
          style={{ padding: 10 }}
          onAccept={(acceptedByScrolling) => {
            setIsModalVisible(false);
          }}
          onDecline={(onDecline) => {
            setIsModalVisible(false);
          }}
          flipButtons
          containerClasses="alert col-lg-12 center"
        >
          <div className="CookieModal">
            <img src={Cookie} className="CookieModal-img" alt="parcel-king" />
            <h1 className="CookieModal-txt1">We uses cookies</h1>
            <h5 className="CookieModal-txt2">
              This website uses cookies to enhance the user experience.
            </h5>
          </div>
        </CookieConsent>
      </Modal>
    </>
  );
};

export default CookieModal;
