import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
function Faq() {
  const FreqAskQue = [
    {
      no: "01",
      que: "How to book a pickup courier service?",
      answer:
        "On Parcel-King’s home page or account dashboard, follow the steps below:   1. Select the ‘Book Now’ option 2. Enter pick-up and delivery information   3. Get a quote and make the payments  4. Your booking is confirmed.  We will pick up the package as soon as we receive your booking and confirmation details.",
    },
    {
      no: "02",
      que: "How do I pay for my booking on my Parcel-King account?",
      answer:
        "It is simple. Just follow the steps below:    1.  Login to your Parcel-King account.  2.  On the dashboard, scroll down to the booking list.   3.Select the booking to be paid and the payment button will appear to the right of the booking list.  4 .You can also open the booking that needs to be paid and the ‘Make Payment’ option will be available to make the necessary payments.",
    },
    {
      no: "03",
      que: "How do I create an invoice with Parcel-King?",
      answer:
        " To create an invoice in Parcel-King, you need to do as follows: 1. Login to your Parcel-King account and go to ‘Invoices’ in the dashboard.  2 .Click on ‘Create Invoice’ and choose the ‘Invoice Customer Details’.  3.Enter the invoice item details like discount/deduction, invoice note, and more.  4.And you’re done. Your invoice is ready to be sent to the clients",
    },
    {
      no: "04",
      que: " What can I do as a Premium customer of Parcel-King?",
      answer:
        " Parcel-King’s premium customers have access to the following services:. Premium delivery . All-day pick-up. Manage your business by adding/managing users, employees, drivers, vehicles, and payroll",
    },
    {
      no: "05",
      que: "  How much does Parcel-King courier service cost?",
      answer:
        " Parcel-King has same-day and next-day courier services starting from €5.95 only.",
    },
    {
      no: "06",
      que: " Is there any VAT on Parcel-King courier services?",
      answer:
        " Yes. Parcel-king adds 23% VAT for every order. This is added onto the final price when a  booking is made and can be seen broken down on your booking infotmsation. ",
    },
    {
      no: "07",
      que: " Do courier services by Parcel-King work on Sunday?",
      answer:
        "Yes. Parcel-King courier collection & delivery is available on weekends and bank holidays.",
    },
    {
      no: "08",
      que: "How do I send something through courier in Ireland?",
      answer:
        "You can use Parcel-King’s courier pick-up and delivery services to send anything in Ireland and elsewhere.",
    },
    {
      no: "09",
      que: "What is a courier service?",
      answer:
        " A courier service is an increasingly famous way to get your letters and parcels delivered without using standard post services. It is a cheap alternative to send anything on-time safely.",
    },
  ];
  return (
    <Container fluid={true}>
      <Row>
        <Col sm="3" xs="12"></Col>

        <Col sm="6" xs="12">
          <h1 className="home-txt4">FAQs</h1>
          <h6 className="home-txt11">
            Questions on Parcel King’s Delivery & Courier Services? We’ve got
            answers.
          </h6>
          {FreqAskQue.map((item) => (
            <Accordion
              style={{
                boxShadow: "0px 0px 0px rgba(0, 0, 0,0)",
                borderBottom: "1px solid #DCDCDC",
                padding: 10,
              }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMore style={{ color: "#346DDC !important" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h4 className="home-txt12">{item.que}</h4>
              </AccordionSummary>
              <AccordionDetails>
                <h6 className="home-txt9">{item.answer}</h6>
              </AccordionDetails>
            </Accordion>
          ))}
        </Col>
        <Col sm="3" xs="12"></Col>
      </Row>
    </Container>
  );
}
export default withRouter(Faq);
