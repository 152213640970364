export const LOGIN_RQUEST = "LOGIN_RQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILD = "LOGIN_FAILD";
export const LOGOUT = "LOGOUT";

export const SAVE_DETAILS = "SAVE_DETAILS";
export const CLEAR_DETAILS = "CLEAR_DETAILS";

export const SAVE_TOKEN = "SAVE_TOKEN";

export const SAVE_CREDIT = "SAVE_CREDIT";

export const CLOCK_INOUT = "CLOCK_INOUT";

export const SHOPIFY_URL = "SHOPIFY_URL";

export const SAVE_PAYMENT = "SAVE_PAYMENT";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT";
export const PAYMENT_TYPE = "PAYMENT_TYPE";
export const PAYMENT_URL = "PAYMENT_URL";

export const SAVE_ADDRESS = "SAVE_ADDRESS";
export const SAVE_PICKUP_ADDRESS = "SAVE_PICKUP_ADDRESS";
export const SAVE_DELIVERY_ADDRESS = "SAVE_DELIVERY_ADDRESS";

export const REFRESH_CREDITS = "REFRESH_CREDITS";
export const SET_LOADING = "SET_LOADING";
